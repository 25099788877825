import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const LineTextLine = makeShortcode("LineTextLine");
const Container = makeShortcode("Container");
const Row = makeShortcode("Row");
const Col = makeShortcode("Col");
const ImageBed = makeShortcode("ImageBed");
const Link = makeShortcode("Link");
const Picture = makeShortcode("Picture");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="Seo" />
    <LineTextLine title="What is New" mdxType="LineTextLine" />
    <Container mdxType="Container">
  <Row mdxType="Row">
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/women-s-portraits/lady-v" mdxType="Link">
          <Picture filename="lady-v-new.jpg" fadeIn mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
  </Row>
  <Row mdxType="Row">
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/women-s-portraits/lady-in-red" mdxType="Link">
          <Picture filename="lady-in-red-line-with-headline.jpg" fadeIn mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
  </Row>
  <Row mdxType="Row">
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
      <Link to="women-s-portraits/questioning-my-faith" mdxType="Link">
        <Picture name="Questioning My Faith" modelName="Daria. Model agency 'Divino Espejo'" place="Barcelona" filename="questioning_my_faith005_square.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/men-s-portraits/vasiliy" mdxType="Link">
        <Picture name="Vasiliy" modelName="Vasiliy Lebedev" place="Barcelona" filename="vasiliy_square.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/women-s-portraits" mdxType="Link">
        <Picture name="Butterfly" modelName="Daria. Model agency 'Divino Espejo'" place="Barcelona" filename="butterfly_square.jpg" mdxType="Picture" />
        </Link>
      </ImageBed>
    </Col>
  </Row>
    </Container>
    <LineTextLine title="See more" mdxType="LineTextLine" />
    <Container mdxType="Container">
  <Row mdxType="Row">
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/men-s-portraits/nikolay" mdxType="Link">
          <ImageBed mdxType="ImageBed">
            <Picture name="Nikolay" modelName="Nikolay Bondarenko" place="Moscow" filename="Nikolay-photography-by-andrey-bond.jpg" mdxType="Picture" />
          </ImageBed>
        </Link>
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/women-s-portraits/daria" mdxType="Link">
          <ImageBed mdxType="ImageBed">
            <Picture name="Daria" modelName="Daria Zakharova" place="Moscow" filename="daria_zakharova_photographer_andrey_bond002.jpg" mdxType="Picture" />
          </ImageBed>
        </Link>
      </ImageBed>
    </Col>
    <Col p5="true" mdxType="Col">
      <ImageBed mdxType="ImageBed">
        <Link to="/men-s-portraits/simon" mdxType="Link">
          <ImageBed mdxType="ImageBed">
            <Picture name="Simon" modelName="Simon Dmitriev" place="Moscow" filename="simon-looks-down.jpg" mdxType="Picture" />
          </ImageBed>
        </Link>
      </ImageBed>
    </Col>
  </Row>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      